.card {
  box-shadow: rgba(0, 123, 255, 0.2) 0px 3px 8px;

  @include media-breakpoint-up(xl) {
    border-radius: $lg-border-radius;
  }

  &-body {
    padding: $spacer-4;

    @include media-breakpoint-up(md) {
      padding: $spacer-5;
    }

    @include media-breakpoint-up(lg) {
      padding: $spacer-6;
    }
  }

  &-unstyled {
    border-radius: 0;
    box-shadow: none;

    .card-body {
      padding: 0;
    }
  }
}
