.imagepicker {
  padding: 0px;
  position: relative;
}
.viewphoto-modal-ImgHolder {
  display: flex;
  justify-content: center;
  overflow: hidden;
  // width: 200px;
  height: 20rem;
  border-radius: 15px;
  background: #ecf6ff 0% 0% no-repeat padding-box;
  border: 2px solid #ecf6ff;
  position: relative;
}

.imagepicker-default {
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  border: 2px dotted #43b69d;
  border-radius: 10px;
  max-width: 20rem;
  height: 12.5rem;
}

.imagepicker-imgHolder {
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 360px;
  height: 100%;
  overflow: hidden;
  border: 2px solid #ecf6ff;
}
#imagepicker-img {
  height: 100%;
  position: absolute;
}
.imagepicker-addicon {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #003a6f;
  border-radius: 360px;
  margin: 0px;
  cursor: pointer;
}
.imagepicker-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  right: -10.5rem;
  z-index: 10;
  width: 183px;
  // height:175px;
  background: #ffffff;
  box-shadow: 5px 5px 10px #5985ac33;
  border: 1px solid #9ac2e5;
  border-radius: 5px;
  opacity: 1;
}
.imagepicker-modal > span {
  display: flex;
  align-items: center;
  width: 153px;
  // height: 35px;

  border-radius: 5px;
  font: 12px Poppins;
  letter-spacing: 0.24px;
  color: #003a6f;
  padding-left: 15px;
  cursor: pointer;
}
.imagepicker-modal > span:hover {
  background: #ecf6ff;
}

.help-text {
  font-size: 14px;
  word-wrap: break-word;
  padding: 0 3rem;
  display: flex;
  align-items: center;
  text-align: center;
}
