.badge {

  &-xs {
    border-radius: $base-border-radius * 0.6; // 6px
    font-size: $xs-font-size;
    padding: ($spacer * 0.2) ($spacer * 0.4); // 4px 8px
  }

  &-sm {
    border-radius: $base-border-radius * 0.75; // 7.5px
    font-size: $sm-font-size;
    padding: ($spacer * 0.25) ($spacer * 0.5); // 5px 10px
  }

  &-lg {
    border-radius: $base-border-radius;
    font-size: $lg-font-size;
    padding: ($spacer * 0.35) ($spacer * 0.7); // 7px 14px
  }

  &-primary-light {
    background-color: $primary-light-color;
    color: $dark-color;

    *[class^="fa"] {
      color: $primary-dark-color;
    }
  }

  &-info-light {
    background-color: $info-light-color;
    color: $dark-color;

    *[class^="fa"] {
      color: $info-color;
    }
  }
}
