.img {

  &-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;

    &-supplier {
      min-width: rem(40px);

      img {
        max-height: rem(20px);
      }
    }

    &-voucher {
      width: rem(36px);

      @include media-breakpoint-up(lg) {
        width: rem(46px);
      }

      img {
        max-height: rem(18px);

        @include media-breakpoint-up(lg) {
          max-height: rem(23px);
        }
      }
    }

    &-warranty-product {
      overflow: hidden;
      border-radius: $base-border-radius * 0.6; // 6px
      height: rem(36px);
      width: rem(36px);

      @include media-breakpoint-up(lg) {
        border-radius: $base-border-radius * 0.6; // 6px
        height: rem(40px);
        width: rem(40px);
      }

      img {
        object-fit: cover;
      }
    }
  }

  &-third-party-logo {
    max-height: rem(30px);
    max-width: rem(76px);

    @include media-breakpoint-up(md) {
      max-height: rem(34px);
      max-width: rem(84px);
    }

    @include media-breakpoint-up(lg) {
      max-height: rem(38px);
      max-width: rem(94px);
    }

    &-sm {
      max-height: rem(24px);
      max-width: rem(60px);

      @include media-breakpoint-up(md) {
        max-height: rem(27px);
        max-width: rem(68px);
      }

      @include media-breakpoint-up(lg) {
        max-height: rem(30px);
        max-width: rem(76px);
      }
    }

    &-lg {
      max-height: rem(38px);
      max-width: rem(94px);

      @include media-breakpoint-up(md) {
        max-height: rem(42px);
        max-width: rem(105px);
      }

      @include media-breakpoint-up(lg) {
        max-height: rem(47px);
        max-width: rem(118px);
      }
    }
  }
}
