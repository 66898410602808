.main {
  &.public-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
}

.intercom-lightweight-app-launcher,
.intercom-namespace .intercom-with-namespace-52k34s {
  bottom: 55px !important;
}

.container,
.container-fluid {
  @include media-breakpoint-up(md) {
    padding-left: $spacer-4;
    padding-right: $spacer-4;
  }

  @include media-breakpoint-up(lg) {
    padding-left: $spacer-6;
    padding-right: $spacer-6;
  }
}

.text-orange {
  color: #fd7e14;
}

.text-lightgreen {
  color: #90ee90;
}

.bg-cayn {
  background-color: #e1fdf7;
  color: #020617;
}

.min-h-100 {
  min-height: 100%;
}

.dropdown-arrow::before {
  content: "";
  position: absolute;
  /* top: 0; */
  left: 20%;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid white;
  bottom: -18px;
}

.dropdown-item {
  border-radius: 8px;
}

@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-new-header {
    display: block;
  }
}

@media (max-width: 1200px) {
  .navbar-expand-xl .navbar-toggler {
    display: block;
  }

  .navbar-new-header {
    display: none;
  }
}

@media (min-width: 992px) {
  .custom-line {
    border-right: 1px solid #b0d9c6;
  }

  .cont {
    height: calc(100vh - 150px);
  }

  .mx-1666 {
    margin-left: 10%;
    margin-right: 10%;
  }
}

@media (max-width: 992px) {
  .custom-line {
    border-bottom: 1px solid #b0d9c6;
  }

  .mtb-6rem {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
}

@media (max-width: 480px) {
  .ellipsisText {
    cursor: pointer;
    white-space: nowrap;
    width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (min-width: 992px) and (max-height: 750px) {
  .cont {
    margin-top: 8.33% !important;
    margin-bottom: 8.33% !important;
  }
}

@media (min-width: 576px) {
  .navbar-username {
    max-width: max-content !important;
  }
}

@media (max-width: 576px) {
  .navbar-username {
    max-width: 10rem !important;
  }
}

@media (max-width: 333px) {
  .margin-bottom-15 {
    margin-bottom: 15rem;
  }
}

.h-100vh {
  height: 100% !important;
}

@media (min-width: 992px) {
  .h-100vh {
    height: 100vh !important;
  }
}

.mb-6rem {
  margin-bottom: 6rem;
}

.mb-10rem {
  margin-bottom: 10rem;
}

.bold-colour {
  color: #020617;
}

.invalid-text {
  color: red;
}

.invalid-border {
  border: 1px solid red !important;
}

.invalid-border-right {
  border-right: 1px solid red !important;
}

.border-radius-right {
  border-radius: 0 12px 12px 0 !important;
}

.br-12 {
  border-radius: 12px;
}

.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}
.fs-30 {
  font-size: 30px;
}

.fs-36 {
  font-size: 36px;
}

.react-tel-input .form-control {
  width: -webkit-fill-available !important;
  height: 41.6px !important;
  border-radius: 0.75rem !important;
  border: 0.0625rem solid #dfe8f1 !important;
}

.react-tel-input .selected-flag {
  background: white;
}

.form-control:focus {
  box-shadow: none;
  border-color: #dfe8f1;
}

.w-fit {
  width: fit-content;
}

.manage-users-card {
  box-shadow: rgba(0, 123, 255, 0.2) 0px 3px 8px;
  padding: 1rem;
  border-radius: 12px;
  margin-bottom: 1rem;
}

.dropdown-menu {
  box-shadow: rgba(0, 123, 255, 0.2) 0px 3px 8px;
  padding: 0;
}

.tab-view .nav-tabs .nav-link {
  background-color: #f5f5f5;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  color: black;
  font-weight: 600;
  font-size: 20px;
}

.tab-view .nav-tabs .nav-link.active {
  background-color: #b0e7d5;
  color: black;
  font-weight: 600;
  font-size: 20px;
}

.wizard-SU {
  box-shadow: rgba(0, 123, 255, 0.2) 0px 3px 8px;
  background: white;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  word-break: break-all;
}

.cursor-pointer {
  cursor: pointer;
}

/* Target the calendar icon */
input[type="date"]::-webkit-calendar-picker-indicator {
  position: relative; /* Allows repositioning */
  right: 2rem; /* Move 10px to the left */
  cursor: pointer; /* Ensures pointer style */
}

.input-group .form-control.is-invalid {
  z-index: 0 !important;
}

.max-width-webkit {
  max-width: -webkit-fill-available; /* Safari and WebKit browsers */
  max-width: -moz-available; /* Firefox */
  max-width: fill-available; /* Standard (if supported in the future) */
}

.form-control,
.input-group-text {
  border: 0.0625rem solid #aabccf;
}

.form-control,
.input-group-text,
.input-group .input-group-text + .form-control,
.input-group .input-group-text + .form-select {
  border-right: 0.0625rem solid #aabccf !important;
}

.btn-upload {
  border: 0.0625rem dashed #aabccf !important;
}

.input-group input {
  margin-bottom: 0;
}

input,
.input-group,
textarea {
  margin-bottom: 4px;
}

.img-wrapper-warranty-product {
  border-radius: 50% !important;
}

.my-calendar-container {
  max-width: 500px;
  margin: 0 auto;
}

.my-calendar-container .react-calendar {
  width: -webkit-fill-available !important;
  border: none !important;
  font-family: "Gilroy", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.my-calendar-container .react-calendar__navigation__label__labelText {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.2;
  color: #020617;
  font-family: "Gilroy", "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: flex;
  width: max-content;
}

.my-calendar-container .react-calendar__month-view__weekdays__weekday {
  display: block;
  font-size: 0.75rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: none;
}

.my-calendar-container .react-calendar__month-view__days__day--weekend,
.my-calendar-container .react-calendar__tile--now {
  color: #020617 !important;
  background-color: transparent !important;
}

.my-calendar-container .react-calendar__tile--active {
  background-color: transparent !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-calendar-container .react-calendar__tile--active abbr {
  background: #36f1cc;
  align-items: center;
  border-radius: 50%;
  display: block;
  display: flex;
  height: 1.5625rem;
  justify-content: center;
  line-height: 1.625rem;
  width: 1.5625rem;
}

/* Move all navigation buttons to the right side */
.my-calendar-container .react-calendar__navigation {
  display: flex;
  justify-content: flex-end; /* Aligns all buttons to the right */
  gap: 5px; /* Adds space between buttons */
}

.my-calendar-container .react-calendar__navigation__label {
  order: -1; /* Moves the label to the left of the buttons */
  margin-right: auto; /* Pushes the buttons to the right */
}

/* Style individual buttons if needed */
.my-calendar-container .react-calendar__navigation__arrow {
  padding: 8px;
  border: none;
  background-color: #fff;
  cursor: pointer;
}

.my-calendar-container .react-calendar__navigation__arrow:hover {
  background-color: #f0f0f0;
}

/* Style the navigation container */
.my-calendar-container .react-calendar__navigation {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures proper spacing between label and buttons */
  gap: 10px;
}

/* Move the label to the left side */
.my-calendar-container .react-calendar__navigation__label {
  flex-grow: 1; /* Allows the label to take up remaining space */
  text-align: left; /* Align the text to the left */
  margin-right: auto; /* Pushes the buttons to the right */
}

/* Style individual navigation buttons */
.my-calendar-container .react-calendar__navigation__arrow {
  padding: 8px;
  border: none;
  background-color: #fff;
  cursor: pointer;
}

/* Add hover effect to buttons */
.my-calendar-container .react-calendar__navigation__arrow:hover {
  background-color: #f0f0f0;
}

.my-calendar-container .react-calendar__navigation button {
  min-width: 0 !important;
}

/* Hide the left-most button (prev2) */
.my-calendar-container .react-calendar__navigation__prev2-button {
  display: none;
}

/* Hide the right-most button (next2) */
.my-calendar-container .react-calendar__navigation__next2-button {
  display: none;
}
/* Style the previous button (‹) */
.my-calendar-container .react-calendar__navigation__prev-button {
  border: 0.0625rem solid #dfe8f1 !important; /* Black border */
  border-radius: 50%; /* Make it circular */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 0;
  min-width: none;
}

/* Style the next button (›) */
.my-calendar-container .react-calendar__navigation__next-button {
  border: 0.0625rem solid #dfe8f1 !important; /* Black border */
  border-radius: 50%; /* Make it circular */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 0;
  min-width: none;
}

/* Optional: Adjust the spacing between buttons */
.my-calendar-container .react-calendar__navigation button {
  margin: 0 5px; /* Add some horizontal spacing */
}

.calendar-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.calendar-navigation {
  display: flex;
  gap: 8px;
}

.month-year {
  font-size: 1.5rem;
  font-weight: 600;
}

.calendar-text {
  display: flex;
  flex-direction: column;
}

.btn-circle {
  border-radius: 50%;
  padding: 5px 10px;
  border: 1px solid #ccc;
}

.my-calendar-container .react-calendar__navigation {
  display: none !important;
}

.w-min {
  width: fit-content;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: #e1fdf7;
}

.fs-14 {
  font-size: 14px;
}

.word-break {
  word-break: break-all;
}

.w-12 {
  width: 12%;
}

.border-reddanger {
  border: 1px solid red !important;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  box-shadow: none;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}

.reminder-box {
  box-shadow: rgba(0, 123, 255, 0.2) 0px 3px 8px;
}

.z-index-99999 {
  z-index: 99999 !important;
}

.story-card {
  border: 1px solid #e1fdf7;
}

.fam-dashboard section {
  min-height: 30rem;
}

.my-calendar-container .react-calendar__month-view__days {
  min-height: 20rem;
}

.dropdown-menu {
  border-radius: 12px;
}

.manage-user .dropdown.show .dropdown-toggle *[class^="fa"] {
  transform: rotate(0deg);
}

.StripeElement .__PrivateStripeElement {
  height: 24px;
  display: flex !important;
  align-items: center;
}

.new-billing-page .nav-tabs .nav-link {
  margin-bottom: 0;
  border-radius: 0;
  font-size: 16px;
}

.new-billing-page .nav-tabs .nav-link.active {
  background-color: #b0e7d5;
  color: black;
  font-weight: 600;
}

.new-billing-page .nav-tabs .nav-link {
  background-color: #f5f5f5;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  color: black;
  font-weight: 600;
}

.new-billing-page .tab-content {
  display: none;
}

.new-billing-page button {
  padding: 8px !important;
  font-size: 14px !important;
}

.new-datepicker .react-date-picker__wrapper,
.new-datepicker .react-datetime-picker__wrapper {
  border: none;
  margin-right: 2rem;
}

.new-datepicker .react-date-picker__calendar,
.new-datepicker .react-datetime-picker__calendar {
  width: 20rem;
}

.new-datepicker .react-date-picker__calendar .react-calendar,
.new-datepicker .react-datetime-picker__calendar .react-calendar {
  top: 3rem;
  position: absolute;
}

.react-datepicker-popper {
  z-index: 99999 !important;
}

.new-datepicker .is-invalid .react-date-picker,
.new-datepicker .is-invalid .react-datetime-picker {
  border-top: 1px solid red;
  border-bottom: 1px solid red;
  border-right: 1px solid red !important;
}
.react-calendar__tile {
  position: relative;
}
.dot {
  width: 6px;
  height: 6px;
  background-color: #36f1cc; /* Customize dot color */
  border-radius: 50%;
  position: absolute;
  bottom: 5px; /* Adjust position */
  left: 50%;
  transform: translateX(-50%);
}

.react-date-picker__inputGroup__input:invalid,
.react-datetime-picker__inputGroup__input:invalid {
  background: transparent !important;
}
