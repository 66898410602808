// Colours
$white: #fff;
$black: #000;

$aquamarine: #36f1cc; // Private - Primary
$mustard: #ffd72a;    // Private - Secondary
$spring: #15cc2b;     // Private - Success
$azure: #003a6f;      // Private - Info
$sundawn: #f38f00;    // Private - Warning
$tomato: #f22630;     // Private - Danger
$seasalt: #f8fafc;    // Private - Light
$rich-black: #020617; // Private - Dark

$mauve: #be81ff;     // Private - Highlight

$cyan: #e1fdf7;          // Private - Primary light
$black-squeeze: #f8fcfb; // Private - Primary lighter
$turquoise: #38b79c;     // Private - Primary dark
$cosmic-latte: #fffbeb;  // Private - Secondary light
$honeydew: #dcf7df;      // Private - Success light
$alice-blue: #f0f9ff;    // Private - Info light
$linen: #fff7ed;         // Private - Warning light
$lavender: #fbdbdd;      // Private - Danger light

$magnolia: #faf5ff; // Private - Highlight lights

$catskill: #f1f5f9;  // Private - Border color
$white-ice: #d9f8f2; // Private - Box shadow color

$fiord: #475569;  // Private - Body color
$regent: #7e8f9e; // Private - Body color secondary

$primary-color: $aquamarine;
$secondary-color: $mustard;
$success-color: $spring;
$info-color: $azure;
$warning-color: $sundawn;
$danger-color: $tomato;
$light-color: $seasalt;
$dark-color: $rich-black;

$highlight-color: $mauve;

$primary-light-color: $cyan;
$primary-lighter-color: $black-squeeze;
$primary-dark-color: $turquoise;
$secondary-light-color: $cosmic-latte;
$success-light-color: $honeydew;
$info-light-color: $alice-blue;
$warning-light-color: $linen;
$danger-light-color: $lavender;

$highlight-light-color: $magnolia;

// Spacing
$spacer: rem(20px);
$spacer-1: $spacer * 0.25;  // 5px
$spacer-2: $spacer * 0.5;   // 10px
$spacer-3: $spacer * 0.75;  // 15px
$spacer-4: $spacer * 1;     // 20px
$spacer-5: $spacer * 1.25;  // 25px
$spacer-6: $spacer * 1.5;   // 30px
$spacer-7: $spacer * 2;     // 40px
$spacer-8: $spacer * 3;     // 60px
$spacer-9: $spacer * 4;     // 80px

// Body
$body-color: $fiord;

$body-secondary-color: $regent;

// Components
$border-width: rem(1px);
$border-color: $catskill;

$base-border-radius: rem(12px);
$md-border-radius: $base-border-radius;                // 12px
$sm-border-radius: $base-border-radius * 0.6666666667; // 8px
$lg-border-radius: $base-border-radius * 1.3333333333; // 16px
$xl-border-radius: $base-border-radius * 2;            // 24px

$md-box-shadow: rem(0 5px 5px 0) $white-ice;
$sm-box-shadow: rem(0 3px 3px 0) $white-ice;
$lg-box-shadow: rem(0 8px 8px 0) $white-ice;

// Typography
$font-family: 'Poppins', 'Helvetica Neue', Helvetica, Arial, sans-serif;  // Private
$headings-family: 'Gilroy', 'Helvetica Neue', Helvetica, Arial, sans-serif;  // Private
$font-family-sans-serif: $font-family;

$base-font-size: rem(16px);
$xs-font-size: $base-font-size * 0.75;  // 12px
$sm-font-size: $base-font-size * 0.875; // 14px
$md-font-size: $base-font-size;         // 16px
$lg-font-size: $base-font-size * 1.125; // 18px

$fw-normal: 400;
$fw-medium: 500;
$fw-semibold: 600;
$fw-bold: 700;
$fw-bolder: 800;

$h1-font-size: $base-font-size * 1.625; // 26px
$h2-font-size: $base-font-size * 1.5;   // 24px
$h3-font-size: $base-font-size * 1.375; // 22px
$h4-font-size: $base-font-size * 1.25;  // 20px
$h5-font-size: $base-font-size * 1.125; // 18px
$h6-font-size: $base-font-size;         // 16px

$lead-font-size: $lg-font-size;

// Buttons + Forms
$inputs-border-color: darken($border-color, 5%);
