.toast {

  @include media-breakpoint-up(md) {
    width: rem(375px);
  }

  @include media-breakpoint-up(lg) {
    width: rem(425px);
  }
}
