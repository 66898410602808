.avatar {
  border-radius: 50%;
  height: rem(42px);
  overflow: hidden;
  width: rem(42px);

  @include media-breakpoint-up(md) {
    height: rem(46px);
    width: rem(46px);
  }

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &-sm {
    height: rem(29px);
    width: rem(29px);

    @include media-breakpoint-up(md) {
      height: rem(32px);
      width: rem(32px);
    }
  }
}
