.btn {

  &-datestamp {
    align-items: center;
    background-color: $white;
    border-radius: $sm-border-radius;
    border: $border-width solid $inputs-border-color;
    color: $dark-color;
    display: flex;
    font-size: $sm-font-size;
    height: rem(32px);
    justify-content: center;
    padding: 0;
    width: rem(32px);

    & .datestamp-day {
      display: block;
      font-family: $headings-family;
      font-size: $sm-font-size;
      font-weight: $fw-bolder;
      line-height: 1;
    }

    & .datestamp-month {
      display: block;
      font-size: $base-font-size * 0.5625; // 9px
      font-weight: $fw-medium;
      line-height: 1;
      margin-top: rem(1px);
      text-transform: uppercase;
    }
  }

  &-white {
    background-color: $white;
    border: $border-width solid $inputs-border-color;
    color: $dark-color;

    &.show,
    &:hover,
    &:active,
    &:first-child:active,
    :not(.btn-check) + &:active {
      background-color: $white;
      border: $border-width solid $inputs-border-color;
      color: $dark-color;

      *[class^="fa"] {
        color: $body-secondary-color;
      }
    }

    *[class^="fa"] {
      color: $body-secondary-color;
    }
  }

  &-circle {
    align-items: center;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    padding: 0;

    &.btn-sm {
      height: rem(24px);
      width: rem(24px);
    }
  }

  &-square {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    padding: 0;

    &.btn-sm {
      height: rem(36px);
      width: rem(36px);
    }
  }

  &-interactive {
    color: $body-secondary-color;

    &:hover {
      color: $dark-color;
      background-color: $light-color;
    }
  }

  &-upload {
    border: $border-width dashed darken($inputs-border-color, 2.5%);
    padding: $spacer-8 $spacer-6;
    transition: .15s all ease-in-out;
    width: 100%;

    @include media-breakpoint-up(md) {
      padding: $spacer-9 $spacer-7;
    }

    &:hover {
      background-color: rgba($light-color, 0.125);
      border: $border-width dashed $primary-color;

      *[class^="fa"] {
        color: $primary-dark-color;
      }
    }

    *[class^="fa"] {
      color: rgba($primary-dark-color, 0.825);
      transition: .15s all ease-in-out;
    }
  }
}
