.toast-middle-top {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1050; /* Ensure it appears above other elements */
  width: max-content;
}

@media (max-width: 768px) {
  .toast-middle-top {
    left: 50%;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .toast-middle-top {
    left: 50%;
  }
}

@media (min-width: 1201px) {
  .toast-middle-top {
    left: 50%;
  }
}
