.calendar {
  overflow-x: scroll;
  padding-bottom: $spacer-2;
  padding-left: $spacer-1;

  @include media-breakpoint-up(md) {
    overflow-x: visible;
    padding-bottom: 0;
    padding-left: 0;
  }

  &-row {
    width: rem(350px);

    @include media-breakpoint-up(md) {
      width: 100%;
    }

    & + .calendar-row {
      margin-top: $spacer-5;
    }

    &:nth-child(2) {
      margin-top: $spacer-3;
    }

    &.is-week {
      position: relative;

      &::before {
        background-color: $primary-lighter-color;
        border-radius: $lg-border-radius;
        content: "";
        height: calc(100% + 15px);
        left: 0;
        position: absolute;
        top: rem(-5px);
        width: 100%;
      }
    }
  }

  &-cell {
    display: block;
    font-size: $xs-font-size;

    &-day {
      align-items: center;
      color: $dark-color;
      display: flex;
      flex-direction: column;
      font-weight: $fw-semibold;
      height: rem(36px);
      position: relative;

      span {
        align-items: center;
        border-radius: 50%;
        display: block;
        display: flex;
        height: rem(25px);
        justify-content: center;
        line-height: rem(26px);
        width: rem(25px);
      }

      &-previous-month,
      &-next-month {
        color: $body-secondary-color;
      }

      &.is-today span {
        background: $primary-color;
      }

      &.has-event::after {
        background-color: $body-secondary-color;
        border-radius: 50%;
        content: "";
        height: rem(5px);
        margin-top: auto;
        width: rem(5px);
      }
    }
  }
}
