// Color system
$white: $white;
$black: $black;

$primary: $primary-color;
$secondary: $secondary-color;
$success: $success-color;
$info: $info-color;
$warning: $warning-color;
$danger: $danger-color;
$light: $light-color;
$dark: $dark-color;

// Spacing
$spacers: (
  0: 0,
  1: $spacer-1,
  2: $spacer-2,
  3: $spacer-3,
  4: $spacer-4,
  5: $spacer-5,
  6: $spacer-6,
  7: $spacer-7,
  8: $spacer-8,
  9: $spacer-9
);

// Body
$body-color: $body-color;
$body-bg: $primary-light-color;

$body-secondary-color: $body-secondary-color;

// Links
$link-decoration: none;

// Paragraphs
$paragraph-margin-bottom: $spacer-3;

// Grid columns
$grid-columns: 24;
$grid-gutter-width: $spacer-5;

// Components
$border-width: $border-width;

$border-color: $border-color;

$border-radius: $md-border-radius;
$border-radius-sm: $sm-border-radius;
$border-radius-lg: $lg-border-radius;
$border-radius-xl: $xl-border-radius;

$box-shadow: $md-box-shadow;
$box-shadow-sm: $sm-box-shadow;
$box-shadow-lg: $lg-box-shadow;

// Typography
$font-family-sans-serif: $font-family;

$font-size-base: $md-font-size;
$font-size-sm: $sm-font-size;
$font-size-lg: $lg-font-size;

$font-weight-normal: $fw-normal;
$font-weight-medium: $fw-medium;
$font-weight-semibold: $fw-semibold;
$font-weight-bold: $fw-bold;
$font-weight-bolder: $fw-bolder;

$h1-font-size: $h1-font-size;
$h2-font-size: $h2-font-size;
$h3-font-size: $h3-font-size;
$h4-font-size: $h4-font-size;
$h5-font-size: $h5-font-size;
$h6-font-size: $h6-font-size;

$headings-margin-bottom: $spacer-2;
$headings-font-family: $headings-family;
$headings-font-weight: $fw-bolder;
$headings-color: $dark-color;

$lead-font-size: $lg-font-size;
$lead-font-weight: $fw-normal;

$text-muted: $body-secondary-color;

$hr-margin-y: 0;
$hr-color: $border-color;

$hr-opacity: 1;

// Buttons + Forms
$input-btn-padding-y: $spacer * 0.4; // 8px
$input-btn-padding-x: $spacer * 0.8; // 16px

$input-btn-padding-y-sm: $spacer * 0.35; // 7px
$input-btn-padding-x-sm: $spacer * 0.7;  // 14px

$input-btn-padding-y-lg: $spacer * 0.45; // 9px
$input-btn-padding-x-lg: $spacer * 0.9;  // 18px

// Buttons
$btn-color: $dark-color;

$btn-font-weight: $fw-semibold;

// Forms
$form-text-margin-top: $spacer-1;
$form-text-font-size: $sm-font-size;
$form-text-color: $body-secondary-color;

$form-label-margin-bottom: $spacer-1;
$form-label-font-weight: $fw-semibold;
$form-label-color: $body-color;

$input-padding-x: $spacer * 0.6; // 12px

$input-padding-x-sm: $spacer * 0.5; // 10px

$input-padding-x-lg: $spacer * 0.7; // 14px

$input-bg: $white;
$input-disabled-bg: $inputs-border-color;
$input-disabled-border-color: $inputs-border-color;

$input-border-color: $inputs-border-color;

$input-placeholder-color: $body-secondary-color;

$form-switch-color: $inputs-border-color;
$form-switch-width: rem(56px);
$form-switch-padding-start: $form-switch-width + $spacer-1;
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>");

$input-group-addon-font-weight: $fw-normal;
$input-group-addon-color: $body-secondary-color;
$input-group-addon-bg: $white;

// Navs
$nav-pills-link-active-color: $dark-color;
$nav-pills-link-active-bg: $primary-color;

// Navbar
$navbar-padding-y: $spacer-3;

$navbar-nav-link-padding-x: 0;

$navbar-brand-font-size: 0;
$nav-link-height: auto;
$navbar-brand-height: auto;
$navbar-brand-padding-y: 0;
$navbar-brand-margin-end: 0;

$navbar-toggler-padding-y: $spacer-1;
$navbar-toggler-padding-x: $spacer-1;
$navbar-toggler-font-size: $h4-font-size;

$navbar-light-color: $dark-color;
$navbar-light-hover-color: $dark-color;
$navbar-light-active-color: $dark-color;
$navbar-light-disabled-color: $body-secondary-color;
$navbar-light-icon-color: $dark-color;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-icon-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");

// Dropdowns
$dropdown-min-width: rem(240px);
$dropdown-padding-y: $spacer-1;
$dropdown-spacer: $spacer-1;
$dropdown-color: $body-color;
$dropdown-bg: $white;
$dropdown-border-color: $border-color;
$dropdown-border-radius: $lg-border-radius;
$dropdown-divider-margin-y: $spacer * 0.3; // 6px

$dropdown-link-color: $body-color;
$dropdown-link-hover-color: $dark-color;
$dropdown-link-hover-bg: $light-color;

$dropdown-link-active-color: $dropdown-link-hover-color;
$dropdown-link-active-bg: $dropdown-link-hover-bg;

$dropdown-link-disabled-color: $body-secondary-color;

$dropdown-item-padding-y: $spacer * 0.3; // 6px
$dropdown-item-padding-x: $spacer;       // 20px

$dropdown-header-color: $body-secondary-color;

// Pagination
$pagination-padding-y: $spacer-1;
$pagination-padding-x: $spacer-2;

$pagination-color: $body-color;
$pagination-bg: $white;
$pagination-border-radius: $sm-border-radius;
$pagination-border-width: 0;
$pagination-margin-start: $spacer-2;

$pagination-focus-color: $body-color;
$pagination-focus-bg: $light-color;

$pagination-hover-color: $body-color;
$pagination-hover-bg: $light-color;

$pagination-active-color: $body-color;

// Cards
$card-spacer-y: $spacer-4;
$card-spacer-x: $spacer-4;
$card-title-spacer-y: 0;
$card-border-width: 0;
$card-border-radius: $lg-border-radius;
$card-bg: $white;

// Toasts
$toast-padding-x: $spacer-3;
$toast-padding-y: $spacer-3;
$toast-font-size: $sm-font-size;
$toast-background-color: $white;
$toast-border-width: 0;
$toast-border-radius: $lg-border-radius;
$toast-box-shadow: $md-box-shadow;

// Badges
$badge-font-size: $md-font-size;
$badge-font-weight: $fw-semibold;
$badge-padding-y: 6px;
$badge-padding-x: 12px;
$badge-border-radius: $base-border-radius * 0.9; // 9px

// Offcanvas
$offcanvas-border-width: 0;
$offcanvas-box-shadow: none;
$offcanvas-horizontal-width: rem(300px);
$offcanvas-transition-duration: 0.15s;
