.outer-modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #414141bf;
  z-index: 100;
  display: none;
}
.uploadphoto-modal-inner {
  width: 884px;
  height: 501px;
  background: #ffffff;
  border: 1px solid #dbfff7;
  border-radius: 10px;
  position: absolute;
  padding: 25px;
}
.uploadphoto-modal-rotatebtn {
  width: 165px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #9ac2e5;
  border-radius: 5px;
  font: 15px Poppins;
  letter-spacing: 0px;
  color: #003a6f;
}
.uploadphoto-modal-rotatebtn > img {
  margin-right: 9px;
}
.uploadphoto-modal-ImgHolder {
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 50vh;
  border-radius: 15px;
  background: #ecf6ff 0% 0% no-repeat padding-box;
  border: 2px solid #ecf6ff;
  position: relative;
}
.uploadphoto-modal-ImgHolder > img {
  position: absolute;
}
.uploadphoto-modal-ImgHolder .upload-label {
  display: block;
  margin: auto;
  border: 1px solid #c0dcf5;
  width: 120px;
  height: 28px;
  border-radius: 20px;
  top: 10px;
  position: relative;
  text-align: center;
  color: #fff;
  background: #212529 0% 0% no-repeat padding-box;
  cursor: pointer;
}
.uploadphoto-modal-ImgHolder span {
  font-size: 14px;
  letter-spacing: 0.6px;
}
.uploadphoto-modal-sliderHolder {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.uploadphoto-modal-slider {
  background: linear-gradient(to right, #9ac2e5 0%, #9ac2e5 100%);
  border-radius: 3px;
  height: 5px;
  width: 250px;
  outline: none;
  transition: "background 450ms ease-in";
  -webkit-appearance: none;
}
.uploadphoto-modal-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: #9ac2e5;
  border: 2px solid #003a6f;
  width: 14px;
  height: 14px;
  border-radius: 360px;
  cursor: pointer;
}
.uploadphoto-modal-plus,
.uploadphoto-modal-closeIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 28px;
  border-radius: 360px;
  background: #003a6f;
  cursor: pointer;
}
.uploadphoto-modal-plus > img {
  height: 65%;
}
.uploadphoto-modal-subtxt {
  font: normal normal normal 16px Poppins;
  color: #003a6f;
}
.uploadphoto-modal-closeIcon {
  position: absolute;
  right: 0;
  margin-right: 25px;
  background: #bcbcbc;
  z-index: 10;
}
.uploadphoto-modal-closeIcon > img {
  height: 85%;
}
.uploadphoto-modal-label {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #36f1cc;
  border: 1px solid #36f1cc;
  border-radius: 36px;
  height: 2.75rem;
  font-size: 0.85rem;
  padding: 0.5rem 2.5rem;
  width: max-content;
  font-size: 0.8rem;
  letter-spacing: 0px;
  color: #003a6f;
  font-weight: 400;
  cursor: pointer;
}
.uploadphoto-modal-label:disabled,
.uploadphoto-modal-label[disabled] {
  cursor: not-allowed;
  border: 1px solid #36f1cc;
  background-color: #36f1cc !important;
  font-size: 0.85rem;
}
.verify-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 124px;
  height: 40px;
  background: #dbfff7;
  border: 1px solid #36f1cc;
  border-radius: 36px;
}

.download-invoice-btn {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 192px;
  height: 40px;
  border: 1px solid var(--unnamed-color-36f1cc);
  background: #dbfff7;
  border: 1px solid #36f1cc;
  border-radius: 36px;
}
.change-btn {
  width: 150px;
  height: 43px;
  border: 1px solid #007ef2;
  border-radius: 22px;
  background: transparent;
}
.subscription-dropdown {
  width: 179px;
  height: 55px;
  background: #ecf6ff;
  border: 1px solid #5985ac;
  border-radius: 5px;
}
.back-dashboard-btn {
  left: 128px;
  width: 237px;
  height: 40px;
  background: #dbfff7;
  border-radius: 5px;
  border: none;
  text-decoration: none;
}
.add-new-card-btn {
  width: 139px;
  height: 40px;
  border: 1px solid #5985ac;
  border-radius: 36px;
  background: transparent;
}
.back-dashboard-btn:hover {
  text-decoration: none;
}
.back-dashboard-btn > img {
  transform: rotate(90deg);
  height: 5px;
}

.paymentmtd-options-modal {
  width: 183px;
  height: 125px;
  right: -140px;
  top: 80px;
}
.blueBtn {
  width: 196px;
  height: 53px;
  background: #ecf6ff 0% 0% no-repeat padding-box;
  border-radius: 36px;
  opacity: 1;
  outline: none;
  border: none;
}
.greenBtn {
  width: 225px;
  height: 53px;
  background: #dbfff7 0% 0% no-repeat padding-box;
  border-radius: 36px;
  opacity: 1;
  outline: none;
  border: none;
}
.delete-profile-icon {
  position: relative;
  // bottom: 1px;
  // left: 100px;
  // cursor: pointer;
  cursor: pointer;
  display: block;
  margin: auto;
  border: 1px solid #c0dcf5;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  text-align: center;
  background: #000 0% 0% no-repeat padding-box;
}
.delete-profile-icon img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
/* Big screen devices (889px and above) */
@media only screen and (min-width: 889px) and (max-device-width: 1024px) {
  .download-invoice-btn {
    width: 130px;
    font-size: 0.65rem;
  }
}
