// Font families
.ff-body {
  font-family: $font-family !important;
}

// Font weights
.fw-normal {
  font-weight: $fw-normal !important;
}

.fw-medium {
  font-weight: $fw-medium !important;
}

.fw-semibold {
  font-weight: $fw-semibold !important;
}

.fw-bold {
  font-weight: $fw-bold !important;
}

.fw-bolder {
  font-weight: $fw-bolder !important;
}

// Font sizes
.small-xs {
  font-size: $xs-font-size !important;
}

// Font colours
.text-primary-dark {
  color: $primary-dark-color !important;
}
