.navbar {
  padding-bottom: $spacer-6;

  @include media-breakpoint-up(md) {
    padding-top: $spacer-4;
  }

  &-brand img {
    height: rem(42px);

    @include media-breakpoint-up(md) {
      height: rem(46px);
    }
  }

  &-nav {

    @include media-breakpoint-up(xl) {
      margin-left: $spacer-6;
    }

    .nav-item + .nav-item {

      @include media-breakpoint-up(xl) {
        margin-left: $spacer-6;
      }
    }

    .nav-link {
      font-weight: $fw-medium;

      @include media-breakpoint-up(xl) {
        padding-bottom: 0;
        padding-top: 0;
        position: relative;
      }

      &::before {

        @include media-breakpoint-up(xl) {
          background-color: $dark-color;
          bottom: -$spacer * 0.6; // -12px
          height: rem(1px);
          left: 50%;
          position: absolute;
          transform: translateX(-50%);
          width: 50%;
        }
      }

      &.active {
        font-weight: $fw-bold;

        &::before {

          @include media-breakpoint-up(xl) {
            content: "";
          }
        }
      }
    }
  }

  &-toggler {
    border-width: 0;
  }

  &-dropdown-toggle {
    max-width: rem(200px);

    @include media-breakpoint-up(md) {
      max-width: rem(225px);
    }

    @include media-breakpoint-up(xl) {
      max-width: rem(250px);
    }
  }
}
