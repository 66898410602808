.nav {

  &-pills {

    .nav-item + .nav-item {
      margin-left: $spacer-2;
    }

    .nav-link {
      font-weight: $fw-semibold;
      background-color: $white;
      box-shadow: $md-box-shadow;
      color: $body-color;

      &.force-inactive {
        background-color: $white;
        font-weight: $fw-medium;
        color: $body-color;
      }
    }

    .show > .nav-link:not(.active) {
      background-color: $white;
      color: $body-color;
    }
  }
}
