// Import core variables for use in Bootstrap and application
@import "./styles/base/mixins";
@import "./styles/base/variables";
@import "./styles/base/bootstrap-variables";
@import "./styles/base/fonts";

// Bootstrap
@import "bootstrap/scss/bootstrap";

// Required assets
// @import "fontawesome";

// Base
@import "./styles/base/base";
@import "./styles/base/typography";
@import "./styles/base/backgrounds";
@import "./styles/base/border";
@import "./styles/base/rounded";

// Layouts
@import "./styles/layouts/main";

// Components
@import "./styles/components/avatars";
@import "./styles/components/badges";
@import "./styles/components/buttons";
@import "./styles/components/calendar";
@import "./styles/components/cards";
@import "./styles/components/dropdowns";
@import "./styles/components/forms";
@import "./styles/components/images";
@import "./styles/components/navbar";
@import "./styles/components/navs";
@import "./styles/components/pagination";
@import "./styles/components/toasts";
