.form {

  &-switch {
    font-size: 0;

    .form-check-input {
      border-color: $inputs-border-color;
      height: rem(28px);

      &:checked {
        border-color: $primary-color;
      }
    }
  }

  &-control,
  &-select {

    &:disabled {
      color: $body-secondary-color;
    }
  }
}

.input-group {

  .form-control,
  .form-select {
    border-right: none;
    padding-right: $input-padding-x / 2;

    & + .input-group-text {
      padding-left: $input-padding-x / 2;
      padding-right: $input-padding-x;

    }
  }

  .input-group-text {
    padding-right: $input-padding-x / 2;

    & + .form-control,
    & + .form-select {
      border-left: none;
      border-right: $border-width solid $inputs-border-color;
      padding-left: $input-padding-x / 2;
      padding-right: $input-padding-x;
    }
  }

  &-sm {

    .form-control,
    .form-select {
      border-right: none;
      padding-right: $input-padding-x-sm / 2;

      & + .input-group-text {
        padding-left: $input-padding-x-sm / 2;
        padding-right: $input-padding-x-sm;

      }
    }

    .input-group-text {
      padding-right: $input-padding-x-sm / 2;

      & + .form-control,
      & + .form-select {
        border-left: none;
        border-right: $border-width solid $inputs-border-color;
        padding-left: $input-padding-x-sm / 2;
        padding-right: $input-padding-x-sm;
      }
    }
  }

  &-lg {

    .form-control,
    .form-select {
      border-right: none;
      padding-right: $input-padding-x-lg / 2;

      & + .input-group-text {
        padding-left: $input-padding-x-lg / 2;
        padding-right: $input-padding-x-lg;

      }
    }

    .input-group-text {
      padding-right: $input-padding-x-lg / 2;

      & + .form-control,
      & + .form-select {
        border-left: none;
        border-right: $border-width solid $inputs-border-color;
        padding-left: $input-padding-x-lg / 2;
        padding-right: $input-padding-x-lg;
      }
    }
  }

  .form-control + .btn,
  .form-select  + .btn {
    border-left: 0;
  }

  // Validation
  &.is-invalid .input-group-text {
    border-color: $danger-color;
  }
}

.input-group:has(.form-control:disabled) .input-group-text {
  background-color: $inputs-border-color;
}
