// reference fonts from src/assets/fonts

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local(''),
    url('../../assets/fonts/gilroy-extrabold.woff2') format('woff2'), // Chrome 26+, Opera 23+, Firefox 39+
    url('../../assets/fonts/gilroy-extrabold.woff') format('woff'); // Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+
}

// Poppins
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
    url('../../assets/fonts/poppins-regular.woff2') format('woff2'), // Chrome 26+, Opera 23+, Firefox 39+
    url('../../assets/fonts/poppins-regular.woff') format('woff'); // Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local(''),
    url('../../assets/fonts/poppins-regular-italic.woff2') format('woff2'), // Chrome 26+, Opera 23+, Firefox 39+
    url('../../assets/fonts/poppins-regular-italic.woff') format('woff'); // Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local(''),
    url('../../assets/fonts/poppins-medium.woff2') format('woff2'), // Chrome 26+, Opera 23+, Firefox 39+
    url('../../assets/fonts/poppins-medium.woff') format('woff'); // Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local(''),
    url('../../assets/fonts/poppins-medium-italic.woff2') format('woff2'), // Chrome 26+, Opera 23+, Firefox 39+
    url('../../assets/fonts/poppins-medium-italic.woff') format('woff'); // Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local(''),
    url('../../assets/fonts/poppins-semibold.woff2') format('woff2'), // Chrome 26+, Opera 23+, Firefox 39+
    url('../../assets/fonts/poppins-semibold.woff') format('woff'); // Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: local(''),
    url('../../assets/fonts/poppins-semibold-italic.woff2') format('woff2'), // Chrome 26+, Opera 23+, Firefox 39+
    url('../../assets/fonts/poppins-semibold-italic.woff') format('woff'); // Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(''),
    url('../../assets/fonts/poppins-bold.woff2') format('woff2'), // Chrome 26+, Opera 23+, Firefox 39+
    url('../../assets/fonts/poppins-bold.woff') format('woff'); // Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local(''),
    url('../../assets/fonts/poppins-bold-italic.woff2') format('woff2'), // Chrome 26+, Opera 23+, Firefox 39+
    url('../../assets/fonts/poppins-bold-italic.woff') format('woff'); // Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+
}
