.bg-primary-light {
  background-color: $primary-light-color;
}

.bg-secondary-light {
  background-color: $secondary-light-color;
}

.bg-success-light {
  background-color: $success-light-color;
}

.bg-danger-light {
  background-color: $danger-light-color;
}

.bg-warning-light {
  background-color: $warning-light-color;
}

.bg-info-light {
  background-color: $info-light-color;
}

.bg-highlight {
  background-color: $highlight-color;
}

.bg-highlight-light {
  background-color: $highlight-light-color;
}
